import React from 'react';
import { HomePage } from './pages/Home.jsx';
import './assets/styles/App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Persona from './pages/Persona.jsx';
// need to look into routing
function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<HomePage />} />
            <Route path='/persona' element={<Persona />} />
            <Route path='/persona/:idiotType' element={<Persona />} />
            <Route path='*' element={<HomePage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
