import React from 'react';
import Card from '@mui/material/Card';
import { IdioticCardHeader } from './IdioticCardHeader';
import { IdioticCardFooter } from './IdioticCardFooter';
import { IdioticCardBody } from './IdioticCardBody';

// a lot of hardcoded junk, just trying to get a poc working
export const IdioticStatementCard = ({ cardDetails }) => {
  const { id, title, titleBadge, truthSnippet, truth, idiotTypes, categories, sources } = cardDetails;
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  const relatedChips = [];

  idiotTypes.map((idiot) => {
    relatedChips.push({
      cat : "idiotType",
      val: idiot
    })
  })

  categories.map((cateogry) => {
    relatedChips.push({
      cat : "categories",
      val: cateogry
    })
  })

  return (
    <Card className="card">
      <IdioticCardHeader idioticCardHeaderProps={{ title, titleBadge, handleExpandClick }} />
      <IdioticCardBody idioticCardBodyProps={{ id, truthSnippet, truth, relatedChips, sources, expanded, idiotTypes }} />
      <IdioticCardFooter idioticCardFooterProps={{ id, expanded, handleExpandClick, idiotTypes }} />
    </Card>
  );
};
