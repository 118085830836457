import React, {useState, useEffect, useRef} from 'react';
import { Box, Typography, Button, Grid, FormControl, OutlinedInput, ClickAwayListener } from '@mui/material';
import { IdioticStatementCard } from '../idioticCard/IdioticStatementCard';
import { sampleContent } from '../../assets/sampleContent/sampleContent';
import { Banner } from './Banner';
import { styled } from '@mui/material';
import  SearchIcon  from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { StyledToolTip } from '../sharedComponents/StyledTooltip';
import { Helmet } from 'react-helmet';

const TopicButton = styled(Button)({
  backgroundColor : 'transparent',
  border: '1px solid #BEBFC1',
  borderRadius: '30px',
  color: '#636364',
  textTransform: 'none',
  marginRight : "5px",
  marginTop : "5px"
})

const TopicItemButton = ({handleButtonClick, option}) => {
  const [selected, setSelected] = useState(false);

  const handleClick = () => {
    setSelected(!selected);
    handleButtonClick(option)
  }
  return (
    <TopicButton onClick={handleClick} sx={ selected ? { backgroundColor : "#E6EFF5" } : {} }>{ selected && <CheckIcon sx={{ color : "#5A6D7A" }} /> } {option}</TopicButton>
  );
}

const TopicPersonaFilter = ({ filterName, options, handleOptionSelect }) => {

  const [toolTipOpen, setToolTipOpen] = useState(false);

  const handleToolTipOpen = () => {
    setToolTipOpen(true);
  }

  const handleToolTipClose = () => {
    setToolTipOpen(false);
  }

  const handleButtonClick = (option) => {
    handleOptionSelect(option);
  }

  return (
    <Box mt="20px">
      <Typography color="#46494B" fontSize="15px" fontWeight="700" sx={{ display: 'flex', alignItems:"center" }}>
        Filter by {filterName}
        { 
          filterName === 'idiot' &&
          <ClickAwayListener onClickAway={handleToolTipClose}>
            <div>
              <StyledToolTip
                onClose={handleToolTipClose}
                open={toolTipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement='top'
                title={
                  <React.Fragment>
                    <Box padding="10px">
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography fontSize="20px" fontWeight="900">What's this?</Typography>
                        <Button sx={{ background: "transparent", border: "none", color: "#536674" }} onClick={handleToolTipClose}><CloseIcon /></Button>
                      </Box>

                      <Box mt="20px">
                        <Typography fontSize="18px" fontWeight="700">
                          Knowing what type of idiot you are will help you find the content most helpful to you. Don't know? Take the quiz and find out!
                        </Typography>
                      </Box>

                      <Box mt="20px" sx={{ display: "flex", justifyContent: "end" }}>
                        <Button className="toolTipButton toolTipClose" onClick={handleToolTipClose}>Close</Button>
                        <Button className="toolTipButton toolTipQuiz">Open Quiz</Button>
                      </Box>
                    </Box>
                  </React.Fragment>
                }
              >
                <HelpOutlineIcon sx={{ cursor: 'pointer', ml: 2 }} onClick={handleToolTipOpen} />
              </StyledToolTip>
            </div>
          </ClickAwayListener>
        }
      </Typography>
      <Box sx={{ my: "10px" }}>
        {options.map((option, key) => (
          <TopicItemButton key={key} handleButtonClick={handleButtonClick} option={option} >{option}</TopicItemButton>
        ))}
      </Box>
    </Box>
  );
}

export const Main = ({ selectedPart }) => {
  const [cardContents, setCardContents] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [ogTitle, setOgTitle] = useState('');
  const [ogDesc, setOgDesc] = useState('');
  const [paramStatementId, setParamStatementId] = useState(0);

  const searchElementRef = useRef(null);
  const contentElementRef = useRef(null);
  const shareContentElementRef = useRef({});
  
  const filterTopics = ['EV fires', 'Safety', 'Cost', 'Battery life', 'Emissions', 'Tesla', 'EV sales', 'Charging', 'Subsidies', 'Child labor', 'Cold weather', 'Climate', 'Reliability', 'Range', 'Hybrid'];
  const filterIdiots = ['Hungry Idiot', 'Curious Idiot', 'Angry Idiot'];

  const filterContents = () => {
    if(filterOptions.length > 0){
      let filteredContent;
      const idiotTypes = sampleContent.filter(item => filterOptions.some(option => item.idiotTypes.includes(option)));
      const categories = sampleContent.filter(item => filterOptions.some(option => item.categories.includes(option)));
      let filteredSet = new Set([...idiotTypes, ...categories]);

      filteredContent = [...filteredSet];

      if(searchText !== ""){
        filteredContent = filteredContent.filter(item => item.title.includes(searchText));
      }

      setCardContents(filteredContent);
    }else{
      let filteredContent = sampleContent;
      if(searchText !== ""){
        filteredContent = sampleContent.filter(item => item.title.includes(searchText));
      }
      setCardContents(filteredContent);
    }
  }

  useEffect(() => {
    setCardContents(sampleContent);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const statementId = urlParams.get('statement');
    if(statementId){
      setParamStatementId(statementId);
      sampleContent.map((content, key) => {
        if(content.id === Number(statementId)){
          setOgDesc(content.truthSnippet);
          setOgTitle(content.title);
        }
        
      });
    }
    setTimeout(() => {
      if(statementId){
        let elementTop = shareContentElementRef.current[statementId].getBoundingClientRect().top + window.scrollY;
        let targetPosition = elementTop - 120;
        window.scrollTo({ behavior : 'smooth', top: targetPosition });
      }
    }, 1000);
  }, []);

  useEffect(() => {
    let elementTop = 0,
        targetPosition = 0,
        targetElement = null;
    if(selectedPart !== ''){


      if(selectedPart === 'search'){
        targetElement = searchElementRef;
      }else if(selectedPart === 'content'){
        targetElement = contentElementRef;
      }


      if(targetElement){
        elementTop = targetElement.current.getBoundingClientRect().top + window.scrollY;
        targetPosition = elementTop - 120;
        window.scrollTo({ behavior : 'smooth', top: targetPosition });
      }
      
    }
  }, [selectedPart]);

  useEffect(() => {
    filterContents();
  }, [filterOptions]);

  const handleOptionSelect = (option) => {
    
    setFilterOptions((prevOptions) => {
      if (prevOptions.indexOf(option) >= 0) {
        return prevOptions.filter((opt) => opt !== option);
      } else {
        return [...prevOptions, option];
      }
    });
  };

  const handleSearchClick = () => {
    filterContents();
  }

  return (
    <main>
      <Box className="mainContainer">
        <Banner />
        <Grid container >
          <Grid ref={searchElementRef} item xs={12} md={7} order={{ xs: 2, md: 1 }}>
            <Box className="searchBoxContainer">
              <FormControl fullWidth sx={{ m: 1 }}>
                <OutlinedInput
                  sx={{ background: "white" }}
                  id="outlined-adornment-amount"
                  startAdornment={<SearchIcon />}
                  placeholder="Search EVs for Idiots"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </FormControl>
              <Button className="btnEvsSearch" onClick={handleSearchClick}>Search</Button>
            </Box>
            <Box sx={{ marginBottom : "10px" }}>
              <TopicPersonaFilter filterName='topic' options={filterTopics} handleOptionSelect={handleOptionSelect} />
              <TopicPersonaFilter filterName='idiot' options={filterIdiots} handleOptionSelect={handleOptionSelect} />
            </Box>
          </Grid>
          <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }}></Grid>
        </Grid>
        <Box sx={{ my : "40px" }} ref={contentElementRef}>
          <Typography fontSize="20px" fontWeight="900" className='countContentsText'>{cardContents.length} idiotic statements</Typography>
        </Box>
        {/* just putting here for poc */}
        {cardContents.map((cardDetails, key) => (
          <Box  ref={(el) => (shareContentElementRef.current[cardDetails.id] = el)} key={key} >
            <IdioticStatementCard cardDetails={cardDetails} />
          </Box>
        ))}
      </Box>
    </main>
  );
};
