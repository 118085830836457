import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import LinkShareModal from '../sharedComponents/LinkShareModal';

export const IdioticCardFooter = ({ idioticCardFooterProps }) => {
  const { id, expanded, handleExpandClick, idiotTypes } = idioticCardFooterProps;
  const [modalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  useEffect(() => {
    const shareLink = `${window.location.origin}?statement=${id}`
    setShareLink(shareLink);
  }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  }

  return (
      <Box className="card-footer">
        <Box>
          <Button aria-label="share" onClick={handleModalOpen}>
            <ShareIcon sx={{ color: "text.secondary" }} />
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                textTransform: "none",
                marginLeft: "8px",
              }}
            >
              Share with an idiot
            </Typography>
          </Button>
          <LinkShareModal link={shareLink} modalOpen={modalOpen} handleCloseModal={handleModalClose} idiotTypes={idiotTypes} />
        </Box>
        <Box>
          <Button className="expand-button" onClick={handleExpandClick}>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", textTransform: "none" }}
            >
              {expanded ? "Close" : "Read more"}
            </Typography>
            <ExpandMoreIcon
              className={`expand-icon ${expanded ? "expanded" : ""}`}
              sx={{ color: "text.secondary" }}
            />
          </Button>
        </Box>
      </Box>
  );
};
