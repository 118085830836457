export const sampleContent = [
  {
    id: 1,
    title: `"Electric vehicles are more expensive than gas-powered vehicles"`,
    titleBadge: 'False',
    truth: [
      `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
          electric vehicles, with many new EV's already being cost-competitive.`,
      `Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    ],
    truthSnippet: `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
            electric vehicles, with many new EV's already being cost-competitive with new...`,
    idiotTypes: [ 'Curious Idiot'],
    categories: ['Cost', 'Maintainance'],
    sources: [
      {
        name: 'The New York Times',
        url: '#'
      },
      {
        name: 'Electricforall.org',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      }
    ],
  },
  {
    id: 2,
    title: `"EVs start on fire often"`,
    titleBadge: 'False',
    truth: [
      ` EVs burn less. 25 fires for every 100,000 sold.  Comparatively, approximately 1,530 gasoline-powered vehicles and 3,475 hybrid vehicles were involved in fires for every 100,000 sold.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    ],
    truthSnippet: `EVs burn less. 25 fires for every 100,000 sold.  Comparatively, approximately 1,530 gasoline-powered vehicles and 3,475 hybrid vehicles were involved in fires for every 100,000 sold...`,
    idiotTypes: ['Angry Idiot', 'Curious Idiot'],
    categories: ['Cost', 'Maintainance'],
    sources: [
      {
        name: 'The New York Times',
        url: '#'
      },
      {
        name: 'Electricforall.org',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      }
    ],
  },
  {
    id: 3,
    title: `"EVs are too expensive to maintain"`,
    titleBadge: 'False',
    truth: [
      `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
          electric vehicles, with many new EV's already being cost-competitive with new Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    ],
    truthSnippet: `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
            electric vehicles, with many new EV's already being cost-competitive with new...`,
    idiotTypes: ['Angry Idiot', 'Curious Idiot'],
    categories: ['Cost', 'Maintainance'],
    sources: [
      {
        name: 'The New York Times',
        url: '#'
      },
      {
        name: 'Electricforall.org',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      }
    ],
  },
  {
    id: 4,
    title: `"EVs cause more lifetime emissions than gas cars"`,
    titleBadge: 'False',
    truth: [
      `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
          electric vehicles, with many new EV's already being cost-competitive with new Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    ],
    truthSnippet: `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
            electric vehicles, with many new EV's already being cost-competitive with new...`,
    idiotTypes: ['Angry Idiot', 'Curious Idiot'],
    categories: ['Cost', 'Maintainance'],
    sources: [
      {
        name: 'The New York Times',
        url: '#'
      },
      {
        name: 'Electricforall.org',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      }
    ],
  },
  {
    id: 5,
    title: `"Electric cars are less safe in water"`,
    titleBadge: 'False',
    truth: [
      `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
          electric vehicles, with many new EV's already being cost-competitive with new Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    ],
    truthSnippet: `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
            electric vehicles, with many new EV's already being cost-competitive with new...`,
    idiotTypes: ['Angry Idiot', 'Curious Idiot'],
    categories: ['Cost', 'Maintainance'],
    sources: [
      {
        name: 'The New York Times',
        url: '#'
      },
      {
        name: 'Electricforall.org',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      }
    ],
  },
  {
    id: 6,
    title: `"EV fires are almost impossible to put out"`,
    titleBadge: 'False',
    truth: [
      `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
           electric vehicles, with many new EV's already being cost-competitive with new Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
           tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
           non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
           consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
           consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    ],
    truthSnippet: `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
            electric vehicles, with many new EV's already being cost-competitive with new...`,
    idiotTypes: ['Angry Idiot', 'Curious Idiot'],
    categories: ['Cost', 'Maintainance'],
    sources: [
      {
        name: 'The New York Times',
        url: '#'
      },
      {
        name: 'Electricforall.org',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      }
    ],
  },
  {
    id: 7,
    title: `"EV sales are slowing down"`,
    titleBadge: 'False',
    truth: [
      `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
           electric vehicles, with many new EV's already being cost-competitive with new Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
           tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
           non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
           consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
           consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    ],
    truthSnippet: `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
            electric vehicles, with many new EV's already being cost-competitive with new...`,
    idiotTypes: ['Angry Idiot', 'Curious Idiot'],
    categories: ['Cost', 'Maintainance'],
    sources: [
      {
        name: 'The New York Times',
        url: '#'
      },
      {
        name: 'Electricforall.org',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      }
    ],
  },
  {
    id: 8,
    title: `"Tax payers pay for EV credits"`,
    titleBadge: 'False',
    truth: [
      `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
           electric vehicles, with many new EV's already being cost-competitive with new Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
           tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
           non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
           consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
           consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    ],
    truthSnippet: `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
            electric vehicles, with many new EV's already being cost-competitive with new...`,
    idiotTypes: ['Angry Idiot', 'Curious Idiot'],
    categories: ['Cost', 'Maintainance'],
    sources: [
      {
        name: 'The New York Times',
        url: '#'
      },
      {
        name: 'Electricforall.org',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      }
    ],
  },
  {
    id: 9,
    title: `"Tesla has a lot of recalls"`,
    titleBadge: 'False',
    truth: [
      `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
           electric vehicles, with many new EV's already being cost-competitive with new Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
           tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
           non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
           consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
           consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    ],
    truthSnippet: `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
            electric vehicles, with many new EV's already being cost-competitive with new...`,
    idiotTypes: ['Angry Idiot', 'Curious Idiot'],
    categories: ['Cost', 'Maintainance'],
    sources: [
      {
        name: 'The New York Times',
        url: '#'
      },
      {
        name: 'Electricforall.org',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      }
    ],
  },
  {
    id: 10,
    title: `"EVs don't help with climate change"`,
    titleBadge: 'False',
    truth: [
      `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
           electric vehicles, with many new EV's already being cost-competitive with new Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
           tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
           non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
           consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
           consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    ],
    truthSnippet: `Electric vehicles cost significantly less than gas-powered vehicles over the two vehicles lifetimes. Upfront costs are dropping for all
            electric vehicles, with many new EV's already being cost-competitive with new...`,
    idiotTypes: ['Angry Idiot', 'Curious Idiot'],
    categories: ['Cost', 'Maintainance'],
    sources: [
      {
        name: 'The New York Times',
        url: '#'
      },
      {
        name: 'Electricforall.org',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      },
      {
        name: 'Some other source',
        url: '#'
      }
    ],
  },
];
