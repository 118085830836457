import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import BannerIdiot from '../../assets/images/idiotGuy.png';
import '../../assets/styles/banner.css';

export const Banner = () => {

  return (
    <Box>
        <Grid container>
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                <Box>
                    <Typography className="bannerTitle">Most of us are still idiots when it comes to Electric Vehicles...</Typography>
                    <Typography className="bannerText" sx={{ maxWidth: "520px" }}>Don't be offended! Whether you are looking to learn the truth about EVs 
                        or here to share information about them... We are here to help!</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={2} order={{ xs: 1, md: 2 }}></Grid>
            <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                <img src={BannerIdiot} style={{ width : "60%" }} className='bannerIdiotImage' />
            </Grid>
        </Grid>
    </Box>
  );
};
