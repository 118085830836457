import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CustomTooltip } from '../sharedComponents/StyledTooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import RedditIcon from '@mui/icons-material/Reddit';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, ClickAwayListener, IconButton, OutlinedInput, InputAdornment, InputLabel, FormControl, Typography } from "@mui/material";

export default function LinkShareModal({ link, modalOpen, handleCloseModal, idiotTypes }) {
    const [open, setOpen] = React.useState(false);
    const [copyTooltipOpen, setCopyTooltipOpen] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState('');

    const handleCopyToolTipClose = () => {
        setCopyTooltipOpen(false);
    }

    const formatString = () => {
        if (idiotTypes.length === 0) {
            return '';
        } else if (idiotTypes.length === 1) {
            return idiotTypes[0];
        } else if (idiotTypes.length === 2) {
            return `${idiotTypes[0]} or ${idiotTypes[1]}`;
        } else {
            const lastElement = idiotTypes.pop();
            return `${idiotTypes.join(', ')}, or ${lastElement}`;
        }
    }

    React.useEffect(() => {
        let idiotTypeString = formatString();
        const title = `You are likely sharing with a(an) ${idiotTypeString}`;
        setModalTitle(title);
    }, [])

    React.useEffect(() => {
        setOpen(modalOpen);
    }, [modalOpen]);


    const handleClose = () => {
        setOpen(false);
        handleCloseModal(false);
    }

    const handleLinkCopy = () => {
        window.navigator.clipboard.writeText(link);
        setCopyTooltipOpen(true);
        setTimeout(() => {
          setCopyTooltipOpen(false);
        }, 1000);
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                }}
            >
                <DialogTitle>
                    <Typography fontWeight="700" fontSize="18px">
                        {modalTitle}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box padding="10px">

                        <Box mt="20px">
                            <Box
                                component="form"
                                className='shareLinkForm'
                            >
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="shareLinkInput">Share with link</InputLabel>
                                    <OutlinedInput
                                        id="shareLinkInput"
                                        value={link}
                                        readOnly
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <ClickAwayListener onClickAway={handleCopyToolTipClose}>
                                                    <div>
                                                        <CustomTooltip
                                                            onClose={handleCopyToolTipClose}
                                                            open={copyTooltipOpen}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener
                                                            title="Link copied to your clipboard"
                                                            slotProps={{
                                                            popper: {
                                                                disablePortal: true,
                                                            },
                                                            tooltip: {
                                                                sx: {
                                                                backgroundColor: "#536674"
                                                                }
                                                            },
                                                            arrow: {
                                                                sx: {
                                                                color: "#536674"
                                                                }
                                                            }
                                                            }}
                                                            placement='top'
                                                            arrow
                                                        >
                                                            <IconButton type="button" sx={{ p: '10px' }} onClick={handleLinkCopy}>
                                                            <ContentCopyIcon />
                                                            </IconButton>
                                                        </CustomTooltip>
                                                    </div>
                                                </ClickAwayListener>
                                            </InputAdornment>
                                        }
                                        label="Share with link"
                                    />
                                </FormControl>
                                
                            </Box>
                        </Box>

                        <Box mt="20px" sx={{ display: "flex", justifyContent: "start" }}>
                            <Button className="shareSocialButtons" href='https://www.facebook.com/' target='_blank'><FacebookRoundedIcon /></Button>
                            <Button className="shareSocialButtons" href='https://x.com/compose/post' target='_blank'><XIcon /></Button>
                            <Button className="shareSocialButtons" href='https://www.reddit.com/submit?type=LINK' target='_blank'><RedditIcon /></Button>
                            <Button className="shareSocialButtons" href="https://www.linkedin.com/feed/?shareActive=true" target='_blank'><LinkedInIcon /></Button>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: "#536674" }} onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
