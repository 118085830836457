import React, { useState, useEffect } from 'react';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import ShareIcon from '@mui/icons-material/Share';
import { Box } from "@mui/material";
import LinkShareModal from '../sharedComponents/LinkShareModal';
import { useNavigate } from 'react-router-dom';

export const IdioticCardBody = ({ idioticCardBodyProps }) => {
  const { id, truthSnippet, truth, relatedChips, sources, expanded, idiotTypes } = idioticCardBodyProps;

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');

  useEffect(() => {
    const shareLink = `${window.location.origin}?statement=${id}`
    setShareLink(shareLink);
  }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  }

  const handleIdiotTypeClick = (cat, val) => {
    if(cat === 'idiotType'){
      navigate(`/persona/${val}`);
    }
  }

  return (
    <Collapse
        in={expanded}
        timeout="auto"
        collapsedSize={40}
      >
        <CardContent
          sx={{ paddingTop: 0, paddingLeft: "20px", paddingRight: "20px" }}
        >
          {!expanded && (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <strong>The Reality: </strong>
              {truthSnippet}
            </Typography>
          )}
          {expanded && 
            truth.map((paragraph, index) => (
            <Box 
              key={index}
            >
              <Typography
                variant="body2"
                sx={{ color: "text.secondary" }}
              >
                {index === 0 ? <strong>The Reality: </strong> : <></>}
                {paragraph}
              </Typography>
            </Box>
          ))}

          {relatedChips.map((chip, index) => (
            <Chip
              key={index}
              label={chip.val}
              variant="outlined"
              className='contentChips'
              onClick={() => handleIdiotTypeClick(chip.cat, chip.val)}
            />
          ))}
          <br />
          <Button
            variant="contained"
            sx={{ backgroundColor: "#536674", margin: "20px 0" }}
            onClick={handleModalOpen}
          >
            <ShareIcon sx={{ marginRight: "10px" }} />
            Share with an idiott
          </Button>
          <LinkShareModal link={shareLink} modalOpen={modalOpen} handleCloseModal={handleModalClose} idiotTypes={idiotTypes} />
          <br />
          <hr />
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            <strong>Sources: </strong>
            {sources?.map((source, index) => (
            <Chip
            key={index}
            label={source.name}
            sx={{
              borderRadius: "8px",
              margin: "8px 8px 8px 12px",
              color: "#7393B3",
            }}
          />
          ))}
          </Typography>
          <hr />
        </CardContent>
      </Collapse>
  );
};
