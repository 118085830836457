import React from 'react';
import { FooterMenu } from '../footerMenu/FooterMenu.jsx';
import { Box, Typography } from '@mui/material';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import RedditIcon from '@mui/icons-material/Reddit';
import XIcon from '@mui/icons-material/X';
import ShareIcon from '@mui/icons-material/Share';

export const PageFooter = () => {

  return (
    <footer className="app-footer">
      <FooterMenu />

      <Box mt="30px">
        <FacebookRoundedIcon sx={{ color : "#536674" }} />
        <XIcon sx={{ color : "#536674", ml: "20px" }} />
        <RedditIcon sx={{ color : "#536674", ml: "20px" }} />
        <ShareIcon sx={{ color : "#536674", ml: "20px" }} />
      </Box>

      <Typography mt="20px">(c) 2024 ADAMAPPS, LLC.</Typography>
    </footer>
  );
};
