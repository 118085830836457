import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

export const IdioticCardHeader = ({ idioticCardHeaderProps }) => {
  const { title, titleBadge, handleExpandClick } = idioticCardHeaderProps;

  const TitleWithBadge = (
    <>
      <Typography
        variant="h6"
        sx={{ color: "text.secondary", display: "inline" }}
      >
        {title}
      </Typography>
      <Chip
        label={titleBadge}
        sx={{ borderRadius: "8px", marginLeft: "10px", backgroundColor: "#880808", color: "#fff", textTransform: "uppercase" }}
      />
    </>
  );

  return <CardHeader title={TitleWithBadge} sx={{ padding: "20px", cursor:"pointer" }} onClick={handleExpandClick} />
};
