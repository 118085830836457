import React from 'react';

import { styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const StyledToolTip = styled(({ className, maxWidth = 320, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ maxWidth }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: maxWidth,
        fontSize: 12,
        border: '1px solid #dadde9',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#f5f5f9',
    },
}));

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#536674",
        color: "white",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#536674",
    },
});