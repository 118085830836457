import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from '@mui/material/useMediaQuery';

export const FooterMenu = () => {
    const isMobile = useMediaQuery('(max-width: 749px)');

  const menuItems = [
    {
      name: "Company",
      items: [
        {
          label: "About",
          url: "#",
        },
        {
          label: "Contact us",
          url: "mailto:evsforidiots@gmail.com",
        },
      ],
    },
    {
      name: "Account",
      items: [
        {
          label: "Sign up",
          url: "#",
        },
        {
          label: "Log in",
          url: "#",
        },
      ],
    },
    {
      name: "Services",
      items: [
        {
          label: "Consultations",
          url: "#",
        },
      ],
    },
    {
      name: "Legal",
      items: [
        {
          label: "Terms of Use",
          url: "#",
        },
        {
          label: "Privacy Policy",
          url: "#",
        },
      ],
    },
  ];

  return (
    <div className="footer-menu">
      {!isMobile ? (
        <div className="desktop">
          <div className="columns">
            {menuItems.map((item, index) => (
              <div className="column" key={index}>
                <p className="column-name">{item.name}</p>
                <ul className="column-items">
                  {item.items.map((item, index) => (
                    <li className="column-item" key={index}>
                      <a className="column-item-link" href={item.url}>
                        {item.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="mobile">
          <div className="columns">
            <ul className="column-items">
                <li className="column-item">
                  <a className="column-item-link" href="#">
                    About
                  </a>
                </li>
                <li className="column-item">
                  <a className="column-item-link" href="mailto:evsforidiots@gmail.com">
                    Contact Us
                  </a>
                </li>
                <li className="column-item">
                  <a className="column-item-link" href="#">
                    Terms of Service
                  </a>
                </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
