import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../components/page/Header';

export default function Persona(){

    const [selectedPart, setSelectedPart] = useState('');


    const { idiotType } = useParams();
    
    useEffect(() => {
        if(idiotType){
            setSelectedPart(idiotType);
        }
    }, []);

    const handleSelectPart = (partName) => {
        setSelectedPart(partName);
        console.log(partName);
    }

    return (
        <>
            <PageHeader page='persona' handlePartSelect={handleSelectPart} />
        </>
    );
}